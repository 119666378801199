import { loginAdmin } from "api/auth.api";
import { notificationController } from "components/notification";
import { writeToken } from "services/localstorage.service";
/* import { clearToken } from "services/localstorage.service"; */

const { useMutation } = require("react-query");

export const useAuthLogin = () => {
  const mutation = useMutation({
    mutationFn: (userInfo) => {
      return loginAdmin(userInfo);
    },
    onSuccess: (res) => {
      if (res.data) {
        writeToken(res.data.accessToken);
        notificationController.success({ message: res.message });
      } else {
        notificationController.error({ message: res.message });
      }
    },
  });

  return mutation;
};

