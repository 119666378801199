import axios from "axios";
import { readToken } from "services/localstorage.service";

const httpApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Accept: "application/json",
  },
});

httpApi.interceptors.request.use(
  (config) => {
    config.headers.authorization = `Bearer ${readToken()}`;
    return config;
  },

  (error) => Promise.reject(error)
);

httpApi.interceptors.response.use(
  (response) => {
    if (response && response) {
      return response.data;
    }
    return response;
  },
  (error) => {
    console.log("error:", error);
    throw error;
  }
);

export default httpApi;
