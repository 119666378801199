// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import Guide from 'layouts/Guide'

// @mui icons
import Icon from "@mui/material/Icon";
import Logout from "layouts/logout";

export const routesProvider = [
  {
    type: "collapse",
    name: "Exchange",
    key: "Exchange",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/Exchange",
    component: <Dashboard link={process.env.REACT_APP_EXCHANGE_URL} />,
  },
  {
    type: "collapse",
    name: "Liquidity",
    key: "Liquidity",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/Liquidity",
    component: <Dashboard link={process.env.REACT_APP_LIQUIDITY_URL} />,
  },
  // {
  //    type: "collapse",
  //    name: "Aggregator",
  //    key: "Aggregator",
  //    icon: <Icon fontSize="small">dashboard</Icon>,
  //    route: "/Aggregator",
  //    component: <Dashboard link={process.env.REACT_APP_AGGREGATOR_URL} />,
  // },
  {
    type: "collapse",
    name: "Oak barrel",
    key: "Oak-barrel",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/Oak-barrel",
    component: <Dashboard link={process.env.REACT_APP_OAKBARREL_URL} />,
  },
  {
    type: "collapse",
    name: "Lottery",
    key: "Lottery",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/Lottery",
    component: <Dashboard link={process.env.REACT_APP_LOTTERY_URL} />,
  },
  {
    type: "collapse",
    name: "Ido",
    key: "ido",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/ido",
    component: <Dashboard link={process.env.REACT_APP_IDO_URL} />,
  },
  {
    type: "collapse",
    name: "Referral",
    key: "Referral",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/Referral",
    component: <Dashboard link={process.env.REACT_APP_REFERRAL_URL} />,
  },
  {
    type: "collapse",
    name: "Marketplace",
    key: "Marketplace",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/Marketplace",
    component: <Dashboard link={process.env.REACT_APP_MARKETPLACE_URL} />,
  },
  {
    type: "collapse",
    name: "Revenue & DAO",
    key: "revenue",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/dao_revenue",
    component: <Dashboard link={process.env.REACT_APP_DAO_URL} />,
  },
  {
    type: "collapse",
    name: "Guide",
    key: "Guide",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    href: '/guidewinery.pdf',
    component: <Guide />,
  }
];

export const routesDefault = [
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
  },
];

export const routesLogout = [
  {
    type: "collapse",
    name: "Logout",
    key: "Logout",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/logout",
    component: <Logout />,
  },
];
