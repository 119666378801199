import { useEffect } from "react"

const URL = process.env.REACT_APP_BASE_URL
const Guide = () => {
  /* useEffect(() => {
    window.location.href = `${URL}/guidewinery.pdf`
  }, []) */
  return <></>
}


export default Guide
