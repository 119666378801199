import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
/* import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData"; */

function Dashboard({ link }) {
  /*   const { sales, tasks } = reportsLineChartData; */

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <iframe
          className="_chart"
          style={{ borderRadius: "10px" }}
          src={link}
          frameBorder="0"
          width="100%"
          height="1000"
          allowTransparency
        />
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
