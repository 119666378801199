import { notificationController } from "components/notification";
import React from "react";
import { Navigate } from "react-router-dom";
import { clearToken } from "services/localstorage.service";

const Logout = () => {
  clearToken();
  notificationController.success({ message: 'Logout' })
  return <Navigate to="/authentication/sign-in" />;
};

export default Logout;
