import { toast } from "react-toastify";
import styled from '@emotion/styled';

const WrapNotification = styled.div`
  color:${props => props.color};
  font-size:14px
`

const Message = ({ message, color }) => {
  return <WrapNotification color={color}>{message}</WrapNotification>
}

const SuccessNotification = ({ message, time }) => {
  toast.success(<Message message={message} color={'green'} />, { autoClose: time | 800 });
};

const WarningNotification = ({ message, time }) => {
  toast.warning(<Message message={message} color={'yellow'} />, { autoClose: time | 800 });
};

const ErrorNotification = ({ message, time }) => {
  toast.error(<Message message={message} color={'red'} />, { autoClose: time | 800 });
};

export const notificationController = {
  success: SuccessNotification,
  warning: WarningNotification,
  error: ErrorNotification,
};

